export function environment() {
  //设置环境
  let type = 4
  let thisEnvironment = {
    AppKey: '',
    secret: '',
    ServeUrl: '',
    type,
  }
  switch (type) {
    case 0: //正式环境
      thisEnvironment = {
        AppKey: '80254689',
        secret: 'BE54F141-36C2-4342-B965-07204F9C67A3',
        ServeUrl: 'https://api.loda.com.kh/20240925/',
        type,
      }
      break
    case 1: //准正式环境
      thisEnvironment = {
        AppKey: '80254689',
        secret: 'BE54F141-36C2-4342-B965-07204F9C67A3',
        ServeUrl: 'https://testapi.loda.com.kh/20211112/',
        type,
      }
      break
    case 2: //测试环境
      thisEnvironment = {
        AppKey: '80254689',
        secret: 'BE54F141-36C2-4342-B965-07204F9C67A3',
        ServeUrl: 'http://api.loda.ltd/',
        type,
      }
      break
    case 3: //开发环境
      thisEnvironment = {
        AppKey: '23534565',
        secret: '5482168584848484659598989',
        ServeUrl: 'https://testapi.loda.net.cn/20210909/',
        type,
      }
      break
    case 4: //开发环境
      thisEnvironment = {
        AppKey: '23534565',
        secret: '5482168584848484659598989',
        ServeUrl: 'https://devapi.loda.net.cn/',
        type,
      }
      break
  }
  return thisEnvironment
}
