export default {
  '粘贴需要代购的中国购物网站的商品网址或者商品名称,例子:':
    '粘贴需要代购的中国购物网站的商品网址或者商品名称,例子:',
  信息: '信息',
  请输入: '请输入',
  收货人: '收货人',
  联系电话: '联系电话',
  邮政编码: '邮政编码',
  收货地址: '收货地址',
  提示: '提示',
  确定: '确定',
  取消: '取消',
  联系人: '联系人',
  地址: '地址',
  不能为空: '不能为空',
  客服介入: '客服介入',
  录入包裹: '录入包裹',
  请选择: '请选择',
  三月内: '三月内',
  半年内: '半年内',
  一年内: '一年内',
  全部: '全部',
  催单成功: '催单成功',
  已收到货: '已收到货',
  最少6位: '最少6位',
  '支付完成后,请根据您支付的情况点击下面按钮':
    '支付完成后,请根据您支付的情况点击下面按钮',
  支付遇到问题: '支付遇到问题',
  支付完成: '支付完成',
  不能小于5或大于150个字符: '不能小于5或大于150个字符',
  已保存: '已保存',
  请选择充值金额: '请选择充值金额',
  请输入用户名: '请输入用户名',
  请输入验证码: '请输入验证码',
  加入购物车中: '加入购物车中',
  继续: '继续',
  购物车详情: '购物车详情',
  电话: '电话',
  邮箱: '邮箱',
  注册成功: '注册成功',
  超出提交最大数量: '超出提交最大数量',
  确认: '确认',
  已删除: '已删除',
  关于乐达: '关于乐达',
  加入乐达: '加入乐达',
  关于: '关于',
  加入: '加入',
  关于我们: '关于我们',
  服务协议: '服务协议',
  帮助中心: '帮助中心',
  服务支持: '服务支持',
  订单详情: '订单详情',
  购物指南: '购物指南',
  如何下单: '如何下单',
  联系方式: '联系方式',
  站点查询: '站点查询',
  工作时间: '工作时间',
  周一至周五: '周一至周五',
  周六至周日: '周六至周日',
  选择部门: '选择部门',
  无查询结果: '无查询结果',
  筛选: '筛选',
  价格: '价格',
  FilterChange: 'FilterChange',
  套用: '套用',
  首页: '首页',
  登录: '登录',
  注册: '注册',
  用户中心: '用户中心',
  卖家中心: '卖家中心',
  新手引导: '新手引导',
  网站导航: '网站导航',
  logout: 'logout',
  退出: '退出',
  海外购: '海外购',
  本地商城: '本地商城',
  店铺: '店铺',
  '粘贴需要代购的中国购物网站的商品网址或者商品名称、':
    '粘贴需要代购的中国购物网站的商品网址或者商品名称、',
  例子: '例子',
  搜索商品: '搜索商品',
  搜索店铺: '搜索店铺',
  支持商家: '支持商家',
  阿里巴巴: '阿里巴巴',
  淘宝: '淘宝',
  天猫: '天猫',
  京东: '京东',
  拼多多: '拼多多',
  所有类别: '所有类别',
  商品: '商品',
  评价: '评价',
  聊天回应率: '聊天回应率',
  回应速度: '回应速度',
  提货仓库: '提货仓库',
  运输方式: '运输方式',
  包裹类目: '包裹类目',
  长度: '长度',
  '(CM)': '(CM)',
  宽度: '宽度',
  高度: '高度',
  重量: '重量',
  '(KG)': '(KG)',
  数量: '数量',
  查询: '查询',
  运费估算: '运费估算',
  仓库地址: '仓库地址',
  包裹信息: '包裹信息',
  新增包裹: '新增包裹',
  快递单号: '快递单号',
  货物名称: '货物名称',
  货物数量: '货物数量',
  '保险服务(申报价值)': '保险服务(申报价值)',
  最大货值金额: '最大货值金额',
  保险费: '保险费',
  木架加固服务: '木架加固服务',
  备注: '备注',
  操作: '操作',
  查看禁运物品: '查看禁运物品',
  发货类型: '发货类型',
  '请选择距您最近的提货仓直发包裹，您会更快的收到包裹。':
    '请选择距您最近的提货仓直发包裹，您会更快的收到包裹。',
  选择仓库: '选择仓库',
  注意事项: '注意事项',
  我已阅读并同意: '我已阅读并同意',
  包裹转运验货规则和服务协议: '包裹转运验货规则和服务协议',
  保险服务及条款: '保险服务及条款',
  修改: '修改',
  提交: '提交',
  支付密码: '支付密码',
  请输入快递单号: '请输入快递单号',
  按城市查询: '按城市查询',
  售后单号: '售后单号',
  类型: '类型',
  状态: '状态',
  介入原因: '介入原因',
  选择快递: '选择快递',
  上传凭证: '上传凭证',
  关闭: '关闭',
  商品信息: '商品信息',
  '退/换货数量': '退/换货数量',
  单价: '单价',
  显示全部: '显示全部',
  原因: '原因',
  退款金额: '退款金额',
  返回设置: '返回设置',
  登录密码修改成功: '登录密码修改成功',
  支付密码修改成功: '支付密码修改成功',
  手机号修改成功: '手机号修改成功',
  邮箱修改成功: '邮箱修改成功',
  新增收货地址: '新增收货地址',
  有效期至: '有效期至',
  发起配送: '发起配送',
  箱子号: '箱子号',
  请输入地址: '请输入地址',
  流水号: '流水号',
  运费: '运费',
  客户代号: '客户代号',
  提交时间: '提交时间',
  预约取货时间: '预约取货时间',
  体积: '体积',
  催单: '催单',
  全选: '全选',
  支付: '支付',
  发起: '发起',
  订单号: '订单号',
  售后中: '售后中',
  取消订单: '取消订单',
  支付订单: '支付订单',
  打印标签: '打印标签',
  取消标签: '取消标签',
  确认签收: '确认签收',
  待补款: '待补款',
  发起售后: '发起售后',
  '订单号/商品名称': '订单号/商品名称',
  采购失败等待操作订单: '采购失败等待操作订单',
  金额: '金额',
  账户余额: '账户余额',
  充值: '充值',
  应付金额: '应付金额',
  交易密码: '交易密码',
  优惠劵: '优惠劵',
  线下充值: '线下充值',
  汇入银行: '汇入银行',
  充值金额: '充值金额',
  充值时间: '充值时间',
  充值证明: '充值证明',
  支付宝充值: '支付宝充值',
  币种: '币种',
  最小充值金额: '最小充值金额',
  最大充值金额: '最大充值金额',
  手续费: '手续费',
  Wing充值: 'Wing充值',
  设为默认: '设为默认',
  默认仓库: '默认仓库',
  售后详情: '售后详情',
  请输入姓名: '请输入姓名',
  手机号: '手机号',
  请输入手机号: '请输入手机号',
  所在地区: '所在地区',
  选择地址: '选择地址',
  详细住址: '详细住址',
  请输入详细住址: '请输入详细住址',
  地址照片: '地址照片',
  设为默认地址: '设为默认地址',
  订单信息: '订单信息',
  充值信息: '充值信息',
  提货信息: '提货信息',
  是否删除: '是否删除',
  查询时间: '查询时间',
  清空: '清空',
  详情: '详情',
  录单时间: '录单时间',
  商品数量: '商品数量',
  提货方式: '提货方式',
  合并: '合并',
  已验证: '已验证',
  物流状态: '物流状态',
  箱子内含件数: '箱子内含件数',
  大陆出库时间: '大陆出库时间',
  实重: '实重',
  计费重量: '计费重量',
  国际运费: '国际运费',
  其他费用: '其他费用',
  总金额: '总金额',
  个人资料: '个人资料',
  用户名: '用户名',
  昵称: '昵称',
  用户代码: '用户代码',
  生日: '生日',
  性别: '性别',
  男: '男',
  女: '女',
  绑定业务员: '绑定业务员',
  保存: '保存',
  我的余额: '我的余额',
  提现: '提现',
  充值记录: '充值记录',
  提现记录: '提现记录',
  收支明细: '收支明细',
  时间: '时间',
  充值渠道: '充值渠道',
  提现卡号: '提现卡号',
  提现金额: '提现金额',
  交易号: '交易号',
  收入: '收入',
  支出: '支出',
  余额: '余额',
  返回我的钱包: '返回我的钱包',
  余额提现: '余额提现',
  输入提现金额: '输入提现金额',
  输入您的交易密码: '输入您的交易密码',
  请选择出您提现的原因: '请选择出您提现的原因',
  可多选: '可多选',
  上传身份信息: '上传身份信息',
  验证身份: '验证身份',
  完成: '完成',
  验证码: '验证码',
  获取验证码: '获取验证码',
  修改登录密码: '修改登录密码',
  请输入旧密码: '请输入旧密码',
  旧密码: '旧密码',
  请输入新密码: '请输入新密码',
  新密码: '新密码',
  再次输入新密码: '再次输入新密码',
  设置交易密码: '设置交易密码',
  验证方式: '验证方式',
  设置支付密码: '设置支付密码',
  确认支付密码: '确认支付密码',
  页面维护中: '页面维护中',
  实力: '实力',
  服务: '服务',
  '完善的售后服务体系：时效保证、商品质量跟踪、退换货保障等':
    '完善的售后服务体系：时效保证、商品质量跟踪、退换货保障等',
  优势: '优势',
  前往首页: '前往首页',
  售后订单号: '售后订单号',
  售后订单: '售后订单',
  发起时间: '发起时间',
  快递公司: '快递公司',
  商品图片: '商品图片',
  商品名称: '商品名称',
  话费充值: '话费充值',
  请输入以0开头的手机号码: '请输入以0开头的手机号码',
  例如: '例如',
  国际快递: '国际快递',
  包裹查询: '包裹查询',
  网点查询: '网点查询',
  如何注册: '如何注册',
  如何充值: '如何充值',
  如何提现: '如何提现',
  安全设置: '安全设置',
  '找到“免费注册”': '找到“免费注册”',
  填写注册信息: '填写注册信息',
  '①“用户名”必填,并且唯一。': '①“用户名”必填,并且唯一。',
  '② 设定登录密码,密码长度不能小于6位数。':
    '② 设定登录密码,密码长度不能小于6位数。',
  '③“邮箱”与“手机号”非必填,若填写,则必须验证。':
    '③“邮箱”与“手机号”非必填,若填写,则必须验证。',
  '④“支付密码”选填,密码长度不能小于6位数。':
    '④“支付密码”选填,密码长度不能小于6位数。',
  '⑤“业务员”（选填,推荐您的业务员工号,如果没有,可不填）。':
    '⑤“业务员”（选填,推荐您的业务员工号,如果没有,可不填）。',
  勾选用户注册协议: '勾选用户注册协议',
  '勾选用户注册协议，点击【用户注册协议】，可查看协议内容':
    '勾选用户注册协议，点击【用户注册协议】，可查看协议内容',
  查找商品: '查找商品',
  加入购物车: '加入购物车',
  提交订单: '提交订单',
  完成支付: '完成支付',
  进入充值页面: '进入充值页面',
  '②点击“充值提现”,进入账户余额与明细页面':
    '②点击“充值提现”,进入账户余额与明细页面',
  '③点击账户余额右侧“充值”,进入充值页面':
    '③点击账户余额右侧“充值”,进入充值页面',
  充值成功: '充值成功',
  进入提现页面: '进入提现页面',
  '①登录之后,点击位于首页上方用户名,进入订单中心':
    '①登录之后,点击位于首页上方用户名,进入订单中心',
  '③点击账户余额右侧“提现”,进入提现页面':
    '③点击账户余额右侧“提现”,进入提现页面',
  安全设置页面: '安全设置页面',
  '①在首页顶部点击您已登录的“用户名”': '①在首页顶部点击您已登录的“用户名”',
  '②安全设置页面': '②安全设置页面',
  '①点击登录密码后面的“修改密码”': '①点击登录密码后面的“修改密码”',
  '②填写新的登录密码、确认登录密码，点击“修改”':
    '②填写新的登录密码、确认登录密码，点击“修改”',
  '③密码修改完成': '③密码修改完成',
  启用交易密码: '启用交易密码',
  '①点击交易密码后面的“立即启用”': '①点击交易密码后面的“立即启用”',
  '③密码设置完成': '③密码设置完成',
  公告: '公告',
  店铺推荐: '店铺推荐',
  更多: '更多',
  账号登录: '账号登录',
  手机登录: '手机登录',
  账号: '账号',
  密码: '密码',
  记住用户: '记住用户',
  忘记密码: '忘记密码',
  '登 录': '登 录',
  免费注册: '免费注册',
  第三方绑定: '第三方绑定',
  绑定: '绑定',
  小时内: '小时内',
  加入时间: '加入时间',
  个月前: '个月前',
  全部商品: '全部商品',
  下单时间: '下单时间',
  补款: '补款',
  收货人信息: '收货人信息',
  手机号码: '手机号码',
  配送信息: '配送信息',
  付款信息: '付款信息',
  付款方式: '付款方式',
  到齐发货: '到齐发货',
  货到即发: '货到即发',
  是否取消: '是否取消',
  取消补款: '取消补款',
  补货款: '补货款',
  小计: '小计',
  申请平台介入: '申请平台介入',
  撤销申请: '撤销申请',
  已付金额: '已付金额',
  补款金额: '补款金额',
  购物车: '购物车',
  正在审核: '正在审核',
  确认支付: '确认支付',
  确认收货: '确认收货',
  合计: '合计',
  留言: '留言',
  支付方式: '支付方式',
  '店铺合计(含运费)': '店铺合计(含运费)',
  店铺合计: '店铺合计',
  '运输方式 ': '运输方式 ',
  查看禁运品: '查看禁运品',
  '商品保险费(非人为破损100%退款)': '商品保险费(非人为破损100%退款)',
  商品质量保障协议: '商品质量保障协议',
  是否拆单: '是否拆单',
  应付总额: '应付总额',
  分享: '分享',
  收藏商品: '收藏商品',
  找同款: '找同款',
  起批量: '起批量',
  '我们承诺您的交易安全:我们只会在买家收到货后拨款给卖家!':
    '我们承诺您的交易安全:我们只会在买家收到货后拨款给卖家!',
  配送至: '配送至',
  该地址不支持配送: '该地址不支持配送',
  商品来自: '商品来自',
  汇率: '汇率',
  库存: '库存',
  给客服留言: '给客服留言',
  总额: '总额',
  采购清单: '采购清单',
  已售完: '已售完',
  更新商品数据: '更新商品数据',
  即时聊天: '即时聊天',
  进入商店: '进入商店',
  推荐商品: '推荐商品',
  商品已下架: '商品已下架',
  '很抱歉，没有淘到该代购商品!': '很抱歉，没有淘到该代购商品!',
  您可以仔细检查输入的代购链接是否正确: '您可以仔细检查输入的代购链接是否正确',
  打开原链接: '打开原链接',
  暂时不支持该链接: '暂时不支持该链接',
  设置新密码: '设置新密码',
  验证: '验证',
  请输再次输入新密码: '请输再次输入新密码',
  新密码设置成功: '新密码设置成功',
  立即登录: '立即登录',
  登录密码: '登录密码',
  请输入登录密码: '请输入登录密码',
  再次输入登录密码: '再次输入登录密码',
  请再次输入登录密码: '请再次输入登录密码',
  请输入邮箱: '请输入邮箱',
  请输入交易密码: '请输入交易密码',
  再次输入交易密码: '再次输入交易密码',
  请输再次输入交易密码: '请输再次输入交易密码',
  业务员: '业务员',
  用户协议: '用户协议',
  LODA隐私权政策: 'LODA隐私权政策',
  的相关店铺: '的相关店铺',
  已下架: '已下架',
  不满足起批量: '不满足起批量',
  '到底了!': '到底了!',
  '购物车空空如也，赶紧挑选商品塞满你的购物':
    '购物车空空如也，赶紧挑选商品塞满你的购物',
  删除: '删除',
  清空购物车: '清空购物车',
  最多提交数量: '最多提交数量',
  已选: '已选',
  件: '件',
  我的专属链接: '我的专属链接',
  我的订单: '我的订单',
  可用余额: '可用余额',
  我的优惠卷: '我的优惠卷',
  热销榜: '热销榜',
  低至: '低至',
  收藏夹: '收藏夹',
  货物中心: '货物中心',
  我的包裹: '我的包裹',
  我的箱子: '我的箱子',
  乐达配送: '乐达配送',
  资产中心: '资产中心',
  我的钱包: '我的钱包',
  我的优惠劵: '我的优惠劵',
  邀请好友: '邀请好友',
  消息通知: '消息通知',
  设置: '设置',
  订单中心: '订单中心',
  热搜词: '热搜词',
  大家都在买: '大家都在买',
  数量必须是: '数量必须是{name}的倍数',
  快递: '快递',
  微信充值: '微信充值',
  交易密码已锁定: '交易密码已锁定，请{name}之后重试',
  不能为0: '不能为0',
  货物类型: '货物类型',
  电子钱包: '电子钱包',
  线下支付: '线下支付',
  银行转账: '银行转账',
  网上银行支付: '网上银行支付',
  请设置提货仓: '请设置提货仓',
  配送费: '配送费',
}
