export const SiteSet: any = () => {
  switch (location.hostname) {
    case 'www.loda.co.id':
      return ID
      break
    case 'www.loda.com.kh':
      return KH
      break
    case 'www.loda.com.pe':
      return PER
      break
    default:
      // return PER
      //  return ID
      return KH
      break
  }
}

//柬埔寨站点
const KH = {
  WebSiteId: 9,
  SearchType1: true, //搜索栏本地商城
  SearchType2: true, //搜索栏店铺
  seller: true,
  LODAMall: true,
  LODALive: true,
  LodaFood: false,
  LODAAPP: true,
  Applink: true,
  ExpressNotices: true,
  RecommendModular: true,
  Activities: true,
  Mailbox: 'Service@loda.com.kh',
  lodaDelivery: true,
  AboutUs: true,
  weixin: true,
  invitation: true,
  article: {
    'zh-cn': [
      1160324,
      null,
      1160371,
      1160340,
      1160379,
      1160402,
      1160423,
      1160378,
      1160506,
    ],
    en: [
      1160325,
      null,
      1160401,
      1160341,
      1160380,
      1160427,
      1160424,
      1160428,
      1160507,
    ],
    km: [
      1160326,
      null,
      1160400,
      1160342,
      1160381,
      1160426,
      1160425,
      1160429,
      1160508,
    ],
  },
  ExternalLogin: true,
  stationLetter: true,
  coupons: true,
  NoEmail: false,
  areaCode: true,
  LODA: 'LODA Cambodia',
  LODAGoogle: 'https://www.googletagmanager.com/gtag/js?id=UA-69628131-1',
  LODAGoogleCode:
    "window.dataLayer = window.dataLayer || [];function gtag() { dataLayer.push(arguments); }gtag('js', new Date());gtag('config', 'UA-69628131-1');",
  logoPng: 'LODA.png',
  login_banner: 'login_banner_cn.jpg',
  login_bgColor: '#dd5246',
  title:
    'LODA កម្ពុជា - Cambodia Online Shopping Platform | Wholesale & Purchasing, International Shipping & Delivery Express, Food Delivery, Mall',
  keywords:
    'LODA,online shopping,purchasing,wholesale,international shipping,delivery,express,food delivery,marketplace,mall,product,coupons,B2B,B2C,online transactions,shopping,China,Cambodia',
  default_description:
    'LODA Cambodia (loda.com.kh) is the largest online shopping e-commerce platform in Cambodia, providing e-commerce services such as online shopping, wholesale & purchasing, international shipping & delivery express, food delivery, local mall. We offer a wide range of products for you to choose from, and various promotions such as coupons and flash sales to help you save even more.',
  ShowMallType: true,
  symbol: '$ ',
  CurrencyCode: 'USD ',
  separator: ',',
  precision: 2,
  lans: {
    km: { val: 'km', name: 'កម្ពុជា។' },
    'zh-cn': { val: 'zh-cn', name: '中文' },
    en: { val: 'en', name: 'English' },
  },
  DefaultMapcenter: { lat: 11.5466075, lng: 104.9005807 },
  getPlacePredictions: 'kh',
  translateLan: 'zh-CN',
  OrderStatu: [
    {
      name: '全部订单',
      StatuId: undefined,
    },
    {
      name: '待审核',
      StatuId: '1',
    },
    {
      name: '待付款',
      StatuId: '2',
    },
    {
      name: '待订购',
      StatuId: '4',
    },
    {
      name: '待发货',
      StatuId: '12',
    },
    {
      name: '待签收',
      StatuId: '5',
    },
    {
      name: '货在中国仓库',
      StatuId: '6',
    },
    {
      name: '已发往海外仓库',
      StatuId: '7',
    },
    {
      name: '海外仓库已签收',
      StatuId: '8',
    },
    {
      name: '客户已收货',
      StatuId: '9',
    },
    {
      name: '交易完成',
      StatuId: '10',
    },
    {
      name: '作废订单',
      StatuId: '11',
    },
    {
      name: '售后订单',
      StatuId: '20',
    },
  ],
  sources: [
    {
      name: '全部',
      val: null,
    },
    {
      name: '海外购',
      val: '5',
    },
    {
      name: '商城',
      val: '10',
    },
    {
      name: '话费',
      val: '40',
    },
  ],
  WorkingHours1_5: '09:00-18:00',
  WorkingHours6_7: '08:00-17:00',
}

//秘鲁站点
const PER = {
  WebSiteId: 1033,
  SearchType1: false, //搜索栏本地商城
  SearchType2: false, //搜索栏店铺
  seller: false,
  LODAMall: false,
  LODALive: false,
  LodaFood: false,
  LODAAPP: true,
  Applink: false,
  ExpressNotices: true,
  RecommendModular: true,
  Activities: true,
  Mailbox: '',
  lodaDelivery: true,
  AboutUs: true,
  weixin: true,
  invitation: false,
  article: {
    'zh-cn': [
      1160512,
      null,
      1160313,
      1160500,
      1160503,
      1160522,
      1160516,
      1160385,
      1160528,
    ],
    en: [
      1160513,
      null,
      1160320,
      1160501,
      1160504,
      1160523,
      1160517,
      1160498,
      1160529,
    ],
    pe: [
      1160514,
      null,
      1160309,
      1160502,
      1160505,
      1160524,
      1160518,
      1160499,
      1160530,
    ],
  },
  ExternalLogin: true,
  stationLetter: true,
  coupons: true,
  NoEmail: true,
  areaCode: true,
  LODA: 'LODA Peru',
  LODAGoogle: 'https://www.googletagmanager.com/gtag/js?id=G-G7BESTT3N8',
  LODAGoogleCode:
    "window.dataLayer = window.dataLayer || [];function gtag() { dataLayer.push(arguments); }gtag('js', new Date());gtag('config', 'G-G7BESTT3N8');",
  logoPng: 'LODA.png',
  login_banner: 'login_banner_cn.jpg',
  login_bgColor: '#dd5246',
  title:
    'LODA កម្ពុជា - Cambodia Online Shopping Platform | Wholesale & Purchasing, International Shipping & Delivery Express, Food Delivery, Mall',
  keywords:
    'LODA,online shopping,purchasing,wholesale,international shipping,delivery,express,food delivery,marketplace,mall,product,coupons,B2B,B2C,online transactions,shopping,China,Cambodia',
  default_description:
    'LODA Cambodia (loda.com.kh) is the largest online shopping e-commerce platform in Cambodia, providing e-commerce services such as online shopping, wholesale & purchasing, international shipping & delivery express, food delivery, local mall. We offer a wide range of products for you to choose from, and various promotions such as coupons and flash sales to help you save even more.',
  ShowMallType: false,
  symbol: 'PEN ',
  CurrencyCode: 'PEN ',
  separator: ',',
  precision: 2,
  lans: {
    pe: { val: 'pe', name: 'Español' },
    'zh-cn': { val: 'zh-cn', name: '中文' },
    en: { val: 'en', name: 'English' },
  },
  DefaultMapcenter: { lat: -9.19, lng: -75.01515 },
  getPlacePredictions: 'pe',
  translateLan: 'en',
  OrderStatu: [
    { name: '全部订单', StatuId: undefined },
    { name: '待付款', StatuId: 1 },
    { name: '订购中', StatuId: 2 },
    { name: '待补款', StatuId: 3 },
    { name: '已入库（中国仓）', StatuId: 4 },
    { name: '国际运输中', StatuId: 5 },
    { name: '已入库（秘鲁仓）', StatuId: 6 },
    { name: '待收货', StatuId: 7 },
    { name: '售后', StatuId: 8 },
  ],
  sources: [
    {
      name: '全部',
      val: null,
    },
    {
      name: '海外购',
      val: '5',
    },
  ],
  WorkingHours1_5: '09:00-18:00',
  WorkingHours6_7: '08:00-17:00',
}

//印尼站点
const ID = {
  WebSiteId: 1032,
  SearchType1: false, //搜索栏本地商城
  SearchType2: false, //搜索栏店铺
  seller: false,
  LODAMall: false,
  LODALive: false,
  LodaFood: false,
  LODAAPP: true,
  Applink: false,
  ExpressNotices: true,
  RecommendModular: true,
  Activities: true,
  Mailbox: 'service@loda.co.id',
  lodaDelivery: true,
  AboutUs: true,
  weixin: true,
  invitation: false,
  article: {
    'zh-cn': [
      1160365,
      null,
      1160313,
      1160500,
      1160503,
      1160500,
      1160479,
      1160497,
      1160506,
    ],
    en: [
      1160366,
      null,
      1160320,
      1160501,
      1160504,
      1160501,
      1160480,
      1160498,
      1160507,
    ],
    id: [
      1160370,
      null,
      1160309,
      1160502,
      1160505,
      1160502,
      1160481,
      1160499,
      1160508,
    ],
  },
  ExternalLogin: true,
  stationLetter: true,
  coupons: true,
  NoEmail: true,
  areaCode: true,
  LODA: 'LODA Indonesia',
  LODAGoogle: 'https://www.googletagmanager.com/gtag/js?id=G-J0QWMTCEP7',
  LODAGoogleCode:
    "window.dataLayer = window.dataLayer || [];function gtag() { dataLayer.push(arguments); }gtag('js', new Date());gtag('config', 'G-J0QWMTCEP7');",
  logoPng: 'LODA.png',
  login_banner: 'login_banner_cn.jpg',
  login_bgColor: '#dd5246',
  title:
    'LODA Indonesia - Indonesia Online Shopping Platform | Wholesale, Purchasing, International Shipping',
  keywords:
    'LODA,online shopping,purchasing,wholesale,international shipping,product,online transactions,shopping,China,Indonesia',
  default_description:
    'LODA Indonesia (loda.co.id) is the largest online shopping e-commerce platform in Indonesia, providing e-commerce services such as online shopping, wholesale &amp; purchasing, international shipping. We offer a wide range of products for you to choose from, and various promotions such as coupons and flash sales to help you save even more.',
  ShowMallType: true,
  symbol: 'Rp ',
  CurrencyCode: 'Rp ',
  separator: '.',
  precision: 0,
  lans: {
    id: { val: 'id', name: 'Indonesia Bahasa' },
    'zh-cn': { val: 'zh-cn', name: '中文' },
    en: { val: 'en', name: 'English' },
  },
  DefaultMapcenter: { lat: -6.194827876301959, lng: 106.82098664232932 },
  getPlacePredictions: 'id',
  translateLan: 'zh-CN',
  OrderStatu: [
    { name: '全部订单', StatuId: undefined },
    { name: '待付款', StatuId: 1 },
    { name: '订购中', StatuId: 2 },
    { name: '待补款', StatuId: 3 },
    { name: '已入库（中国仓）', StatuId: 4 },
    { name: '国际运输中', StatuId: 5 },
    { name: '已入库（印尼仓）', StatuId: 6 },
    { name: '待收货', StatuId: 7 },
    { name: '售后', StatuId: 8 },
  ],
  sources: [
    {
      name: '全部',
      val: null,
    },
    {
      name: '海外购',
      val: '5',
    },
  ],
  WorkingHours1_5: '09:00-18:00',
  WorkingHours6_7: '休息',
}

//世界到家站点
const ZTO = {
  WebSiteId: 9,
  SearchType1: false, //搜索栏本地商城
  SearchType2: false, //搜索栏店铺
  seller: false,
  LODAMall: false,
  LODAPurchase: '海外购',
  LODALive: false,
  LodaFood: true,
  LODAAPP: false,
  LODAExpress: 'Express',
  Welcome: 'Welcome',
  Applink: false,
  ExpressNotices: false,
  RecommendModular: false,
  Activities: false,
  LODA: 'ztoworld',
  Mailbox: false,
  lodaDelivery: false,
  AboutUs: false,
  weixin: false,
  invitation: false,
  article: {
    //ztoworld协议
    'zh-cn': [
      1160485, 1160394, 1160371, 1160340, 1160379, 1160402, 1160482, 1160488,
    ],
    en: [
      1160486, 1160395, 1160401, 1160341, 1160380, 1160427, 1160483, 1160489,
    ],
    km: [
      1160487, 1160396, 1160400, 1160342, 1160381, 1160426, 1160484, 1160490,
    ],
  },
  LODAGoogle: 'https://www.googletagmanager.com/gtag/js?id=UA-69628131-1',
  LODAGoogleCode:
    "window.dataLayer = window.dataLayer || [];function gtag() { dataLayer.push(arguments); }gtag('js', new Date());gtag('config', 'UA-69628131-1');",
  logoPng: 'ztoworld_logo.png',
  ExternalLogin: false,
  stationLetter: false,
  coupons: false,
  login_banner: 'login_banner_cn_2.jpg',
  login_bgColor: '#eafefd',
  NoEmail: false,
  areaCode: false,
  title:
    'ztoworld - Cambodia Online Shopping | Wholesale & Purchasing, Delivery Express',
  keywords:
    'online shopping,purchasing,international shipping,delivery,express,shopping',
  default_description:
    'ztoworld is online shopping e-commerce platform, providing e-commerce services such as online shopping, purchasing, international shipping & delivery express.',
  ShowMallType: true,
  symbol: '$ ',
  CurrencyCode: 'USD ',
  lans: {
    'zh-cn': { val: 'zh-cn', name: '中文' },
    en: { val: 'en', name: 'English' },
  },
  DefaultMapcenter: { lat: 11.5466075, lng: 104.9005807 },
  translateLan: 'zh-CN',
  OrderStatu: [
    {
      name: '全部订单',
      StatuId: undefined,
    },
    {
      name: '待审核',
      StatuId: '1',
    },
    {
      name: '待付款',
      StatuId: '2',
    },
    {
      name: '待订购',
      StatuId: '4',
    },
    {
      name: '待发货',
      StatuId: '12',
    },
    {
      name: '待签收',
      StatuId: '5',
    },
    {
      name: '货在中国仓库',
      StatuId: '6',
    },
    {
      name: '已发往海外仓库',
      StatuId: '7',
    },
    {
      name: '海外仓库已签收',
      StatuId: '8',
    },
    {
      name: '客户已收货',
      StatuId: '9',
    },
    {
      name: '交易完成',
      StatuId: '10',
    },
    {
      name: '作废订单',
      StatuId: '11',
    },
    {
      name: '售后订单',
      StatuId: '20',
    },
  ],
  sources: [
    {
      name: '全部',
      val: null,
    },
    {
      name: '海外购',
      val: '5',
    },
    {
      name: '商城',
      val: '10',
    },
    {
      name: '话费',
      val: '40',
    },
  ],
  WorkingHours1_5: '09:00-18:00',
  WorkingHours6_7: '08:00-17:00',
}
