import Request from './request'
import { AxiosResponse } from 'axios'
import type { RequestConfig } from './request/types'
import { environment } from '@/utils/environment'
import { SiteSet } from '@/utils/siteConfig'
export interface HttpResponse<T> {
  statusCode: number
  desc: string
  result: T
}

// 重写返回类型
interface HttpRequestConfig<T, R> extends RequestConfig<HttpResponse<R>> {
  data?: T
}

const request2 = new Request({
  baseURL: 'https://api.loda.com.kh/20230512/',
  timeout: 1000 * 60 * 5,
  interceptors: {
    // 请求拦截器
    requestInterceptors: (config) => config,
    // 响应拦截器
    responseInterceptors: (result: AxiosResponse) => {
      return result
    },
  },
  headers: {
    'Custom-Auth-Type': 'Customer',
    websiteid: sessionStorage.getItem('wid'),
  },
})

const request = new Request({
  baseURL: environment().ServeUrl,
  timeout: 1000 * 60 * 5,
  interceptors: {
    // 请求拦截器
    requestInterceptors: (config) => config,
    // 响应拦截器
    responseInterceptors: (result: AxiosResponse) => {
      return result
    },
  },
  headers: {
    'Custom-Auth-Type': 'Customer',
    websiteid:
      environment().type == 3 || environment().type == 4
        ? 12
        : SiteSet().WebSiteId,
    // websiteid:  SiteSet().WebSiteId,
  },
})

/**
 * @description: 函数的描述
 * @generic D 请求参数
 * @generic T 响应结构
 * @param {HttpRequestConfig} config 不管是GET还是POST请求都使用data
 * @returns {Promise}
 */
export const HttpRequest = <D = any, T = any>(
  config: HttpRequestConfig<D, T>
) => {
  const { method = 'GET' } = config
  if (method === 'get' || method === 'GET') {
    config.params = config.data
  }
  return request.request<HttpResponse<T>>(config)
}

export const HttpRequest2 = <D = any, T = any>(
  config: HttpRequestConfig<D, T>
) => {
  const { method = 'GET' } = config
  if (method === 'get' || method === 'GET') {
    config.params = config.data
  }
  return request2.request<HttpResponse<T>>(config)
}
// // 取消请求
export const cancelRequest = (url: string | string[]) => {
  return request.cancelRequest(url)
}
// // 取消全部请求
export const cancelAllRequest = () => {
  return request.cancelAllRequest()
}

export default HttpRequest
