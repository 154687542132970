import OSS from 'ali-oss'
import * as Api from '@/utils/api'
import { ElMessage } from 'element-plus'
import VueI18n from '@/language/index'
import moment from 'moment'

export function OssUpload(file, Type) {
  return new Promise((resolve, reject) => {
    //判断扩展名
    const tmpcnt = file.name.lastIndexOf('.')
    const exname = file.name.substring(tmpcnt + 1)
    const names = ['jpg', 'jpeg', 'png']
    if (names.indexOf(exname) < 0) {
      ElMessage.error('wrong format!')
      reject(false)
    } else {
      const fileName = file.uid + '.' + exname
      Api.Post('basic/api/OssAuthorization', {
        Type,
      })
        .then((res: any) => {
          const key: any = res
          const client = new OSS({
            accessKeyId: key.KeyId,
            accessKeySecret: key.KeySecret,
            stsToken: key.Token,
            region: 'oss-' + key.RegionId,
            bucket: key.BucketName,
            endpoint: key.EndPoint,
            secure: true,
            timeout: 15000,
          })
          const headers = {
            'Cache-Control': 'no-cache',
          }
          client
            .put(
              key.Catalog + moment().format('YYYYMMDD') + '/' + fileName,
              file,
              headers
            )
            .then((res: any) => {
              if (key.DomainName) {
                resolve(key.DomainName + res.url.split('com/')[1])
              } else {
                resolve(res.url)
              }
            })
            .catch((err) => {
              switch (err.code) {
                case 'ConnectionTimeoutError':
                  // ElMessage.error(VueI18n.global.t('上传超时,请重试'))
                  break
                default:
                  // ElMessage.error(VueI18n.global.t('上传错误'))
                  break
              }

              reject(err)
            })
        })
        .catch((err) => {
          reject(err)
        })
    }
  })
}
